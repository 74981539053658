import { ThemeSelectors, TypeFiesta } from "@at/design-system-foundation";
import styled, { css } from "styled-components";

import { PrintStyleSheet } from "../../seller/advert-seller-details/location/location.styles";
import { MobilePadding } from "../advert-overview.styles";

export const Container = styled.section`
  ${MobilePadding}
`;

export const BuyingPanelCta = styled.div<{ spacing?: boolean }>`
  @media screen and (min-width: 991px) {
    a:nth-child(2) {
      display: none;
    }
  }
  @media screen and (max-width: 990px) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: ${({ theme, spacing }) =>
      spacing ? `${ThemeSelectors.getGroupThreeSpacing(theme)} 19px` : "0"};
    margin: 0 auto;
    background: ${({ theme }) => ThemeSelectors.getWhite(theme)};
    z-index: 3;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    border-top: 1px solid ${({ theme }) => ThemeSelectors.getGrey(theme)};
    overflow-x: visible;
    -webkit-backface-visibility: hidden;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;

    a:nth-child(2) {
      display: initial;
      margin-right: 0;
    }
  }
`;

export const PricingContainer = styled.div`
  flex-grow: 1;

  & button:first-of-type {
    text-align: left;
  }
`;

export const BuyingPanelCtaContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  ${PrintStyleSheet}

  button.atds-button,
  a.atds-button {
    flex-grow: 1;
    margin-right: 20px;

    &:disabled {
      color: ${({ theme }) => ThemeSelectors.getCopyColor(theme)};
    }
    &:focus {
      color: white;
    }
  }
`;

export const StickyNavBar = styled.div<{ isReserved: boolean }>`
  position: fixed;
  bottom: 0;
  left: 0;
  height: 86px;
  width: 100%;
  background-color: white;
  z-index: 3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px 8px 0 0;
  box-shadow: 0 2px 10px 0 #00000026;
  padding: 16px;
  ${({ isReserved }) => isReserved && `gap 10px;`};

  @media screen and (max-width: 340px) {
    justify-content: center;
  }
`;

export const BuyingPanelDealBuilderReserveContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  margin: ${({ theme }) => ThemeSelectors.getGroupThreeSpacing(theme)} 0 0;

  button.atds-button,
  a.atds-button {
    flex-grow: 1;
  }
`;

export const PriceOverviewContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 15px;
`;

export const PriceConfidence = styled.div``;

export const Margin = styled.span`
  ${({ theme }) => css`
    display: flex;
    margin-top: calc(${ThemeSelectors.getGridUnit(theme)} * 0.5);
  `}
`;

export const nonStickyCTA = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: ${({ theme }) => ThemeSelectors.getGroupFiveSpacing(theme)};
  position: relative;
  button.atds-button,
  a.atds-button {
    flex-grow: 1;

    &:disabled {
      color: ${({ theme }) => ThemeSelectors.getCopyColor(theme)};
    }
    &:focus {
      color: white;
    }
  }
`;
export const PriceDetailLine = styled.div<{
  borderTop?: boolean;
  boldPrice?: boolean;
  borderBottom?: boolean;
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => ThemeSelectors.getGrey(theme)};
  padding-bottom: ${({ borderBottom, theme }) =>
    borderBottom ? ThemeSelectors.getGroupThreeSpacing(theme) : "0px"};
  border-bottom: 1px solid
    ${({ theme, borderBottom }) =>
      borderBottom ? ThemeSelectors.getGrey(theme) : "white"};
`;

export const Save = styled(TypeFiesta).attrs({ as: "span", weight: 500 })`
  ${({ theme }) => css`
    color: ${ThemeSelectors.getATRed(theme)};
    margin-left: 18px;
    margin-top: 0;
    &.pre-reg {
      margin-left: 0;
    }
    @media print {
      color: #000000;
    }
  `}
`;

export const RRPValue = styled.span`
  ${({ theme }) => css`
    text-decoration: line-through;
    color: ${ThemeSelectors.getLegacyCopyLightColor(theme)};
  `}
`;
